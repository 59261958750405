

a:active {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}


.top:hover {
    color: #DE91ED;
}
